/**
 * Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
 */

import { defineStore } from 'pinia'

export const useGlobalInfo = defineStore('globalInfo', {
    state: () => ({
        loggedInUser: undefined,
    }),
    actions: {
        updateLoggedInUser(data) {
            if (data.globalInfo && data.globalInfo.loggedInUser) {
                this.loggedInUser = data.globalInfo.loggedInUser;
            }
        }
    },
})